export function getFormattedDate(date, delimiter, includeTime = false) {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  let formattedDate = delimiter
    ? `${day}${delimiter}${month}${delimiter}${year}`
    : `${month}/${day}/${year}`;

  if (includeTime) {
    let hours = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    let seconds = date.getSeconds().toString().padStart(2, "0");
    formattedDate += ` ${hours}:${minutes}:${seconds}`;
  }

  return formattedDate;
}
